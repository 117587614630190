import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import ComplaintsEmailEventChart from './ComplaintsEmailEventChart';

const ComplaintsEmailEvent = ({ chartData, className }) => {
  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Card.Header className="border-bottom">
        <h5 className="mb-0 mt-2">Complaints</h5>
        <p className="mb-0 fs-11">
          The percentage of emails reported as spam by the recipients.
        </p>
      </Card.Header>

      <Card.Body>
        <ComplaintsEmailEventChart data={chartData['Sent']} event="Sends" />
      </Card.Body>
    </Card>
  );
};

ComplaintsEmailEvent.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.array),
  className: PropTypes.string.isRequired
};

export default ComplaintsEmailEvent;
