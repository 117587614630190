import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Table,
  Card,
  Button,
  Dropdown,
  Spinner,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { FaEdit, FaCaretUp, FaCaretDown } from 'react-icons/fa';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import './styles.scss';
import { useAppSelector } from 'redux/store';
import { selectUser } from 'redux/reducer/auth';
import RequestModal from 'components/user-access-request/RequestModal';
import CustomPagination from 'components/pagination';
import SubtleBadge from 'components/common/SubtleBadge';
import classNames from 'classnames';

const ASC = 'ASC';
const DESC = 'DESC';

const UserAccessRequestPage = () => {
  const request = useAppSelector(selectUser);
  const [isFetching, setIsFetching] = useState(true);

  const [requests, setRequests] = useState([]);

  const [showRequestModal, setShowRequestModal] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [mode, setMode] = useState('ADD');
  const [requestDetail, setRequestDetail] = useState();
  const [sort, setSort] = useState({
    fieldName: 'fullName',
    order: ASC
  });

  const fetchRequests = async () => {
    setIsFetching(true);
    try {
      const response = await axiosClient.get(
        `/requests?page=${page}&limit=${limit}&search=${search}&sort=${sort.fieldName}:${sort.order}`
      );
      setRequests(response.data.requests);
      setTotal(response.data.total);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get user access request');
      }
    }
    setIsFetching(false);
  };

  const getArrowStyles = (fieldName, order) => {
    if (sort.fieldName === fieldName && sort.order === order) {
      return {
        color: '#748194'
      };
    } else {
      return {
        color: '#d8e2ef'
      };
    }
  };

  const onSortChange = fieldName => {
    if (sort.fieldName === fieldName) {
      setSort({
        fieldName,
        order: sort.order === ASC ? DESC : ASC
      });
    } else {
      setSort({
        fieldName,
        order: ASC
      });
    }
  };

  useEffect(() => {
    if (limit) {
      setPageCount(Math.ceil(total / limit));
    }
  }, [limit, total]);

  useEffect(() => {
    if (request) {
      fetchRequests();
    }
  }, [limit, page, request, sort]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
      setPage(1);
    }, 300);

    // Clear the timeout if search changes (before the delay ends)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (request) {
      fetchRequests();
    }
  }, [debouncedSearch]);

  return (
    <>
      <h4 className="">User Access Request</h4>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <Card className="overflow-hidden">
            <Card.Header className="col-12 d-flex flex-wrap gap-2 justify-content-between border-bottom">
              <input
                className="input-value"
                label="Value"
                name="value-0"
                type="text"
                placeholder="Search for full name, email"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </Card.Header>
            <Card.Body>
              <Table
                responsive
                style={{
                  height:
                    isFetching || requests.length < 0
                      ? 'calc(100vh - 250px)'
                      : ''
                }}
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('fullName')}
                    >
                      <div className="d-flex" style={{ minWidth: 130 }}>
                        Full name
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('fullName', ASC)} />
                          <FaCaretDown
                            style={getArrowStyles('fullName', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('email')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 220 }}
                      >
                        Email
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('email', ASC)} />
                          <FaCaretDown style={getArrowStyles('email', DESC)} />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('status')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 130 }}
                      >
                        Status
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('status', ASC)} />
                          <FaCaretDown style={getArrowStyles('status', DESC)} />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isFetching ? (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : requests.length > 0 ? (
                    requests.map((request, index) => (
                      <tr key={`request-${index}`}>
                        <td>{request.fullName}</td>
                        <td>{request.email}</td>
                        <td>
                          <SubtleBadge
                            bg={classNames({
                              success: request.status === 'Approved',
                              info: request.status === 'Disapproved'
                            })}
                            className="me-1"
                          >
                            {request.status}
                          </SubtleBadge>
                        </td>
                        <td className="px-0">
                          <div className="d-flex gap-2">
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  style={{ position: 'fixed', zIndex: 1000 }}
                                  id="overlay-trigger-example"
                                >
                                  Edit
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="outline-info"
                                onClick={e => {
                                  e.stopPropagation();
                                  setShowRequestModal(true);
                                  setMode('EDIT');
                                  setRequestDetail(request);
                                }}
                              >
                                <FaEdit />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        No requests found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="position-relative">
                <CustomPagination
                  page={page}
                  pageCount={pageCount}
                  setPage={setPage}
                />
                {requests.length > 0 && (
                  <Dropdown
                    className="segment-dropdown position-absolute top-0 end-0"
                    style={{ minWidth: '70px' }}
                    onSelect={e => {
                      setLimit(e);
                      setPage(1);
                    }}
                  >
                    <Dropdown.Toggle variant="falcon-default text-capitalize">
                      {limit}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-2">
                      {[10, 20, 50, 100].map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={item}
                          className="text-capitalize"
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <RequestModal
        showRequestModal={showRequestModal}
        setShowRequestModal={setShowRequestModal}
        fetchRequests={fetchRequests}
        requestDetail={requestDetail}
        mode={mode}
      />
    </>
  );
};

export default UserAccessRequestPage;
