import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Table,
  Card,
  Button,
  Dropdown,
  Spinner,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { GoCheckCircle } from 'react-icons/go';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import {
  FaRegSquare,
  FaCheckSquare,
  FaMinusSquare,
  FaEdit,
  FaCaretUp,
  FaCaretDown,
  FaAddressBook
} from 'react-icons/fa';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import './styles.scss';
import { useAppSelector } from 'redux/store';
import { selectUser } from 'redux/reducer/auth';
import AddContactModal from 'components/contacts/AddContactModal';
import UpdateContactModal from 'components/contacts/UpdateContactModal';
import { useSearchParams } from 'react-router-dom';
import DeleteContactModal from 'components/contacts/DeleteContactModal';
import CustomPagination from 'components/pagination';
import { AiFillDelete } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa6';

const ASC = 'ASC';
const DESC = 'DESC';

const ContactsPage = () => {
  const user = useAppSelector(selectUser);
  const [isFetching, setIsFetching] = useState(true);

  const [searchParams] = useSearchParams();
  const segments = searchParams.get('segments') || '';
  const groupId = searchParams.get('groupId') || '';

  const [contacts, setContacts] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [showAddContactModal, setShowAddContactModal] = useState(false);

  const [showUpdateContactModal, setShowUpdateContactModal] = useState(false);
  const [updatingContact, setUpdatingContact] = useState();

  const [showDeleteContactModal, setShowDeleteContactModal] = useState(false);
  const [deletingContactIds, setDeletingContactIds] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  const [sort, setSort] = useState({
    fieldName: 'first_name',
    order: ASC
  });

  const fetchContacts = async () => {
    setIsFetching(true);
    try {
      const response = await axiosClient.get(
        `/contacts?page=${page}&limit=${limit}&search=${search}&sort=${sort.fieldName}:${sort.order}&segments=${segments}&groupId=${groupId}`
      );
      const _contacts = response.data.contacts.map(contact => ({
        ...contact,
        selected: false
      }));
      setContacts(_contacts);
      setTotal(response.data.total);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get contacts');
      }
    }
    setIsFetching(false);
  };

  const fetchCustomFields = async () => {
    try {
      const response = await axiosClient.get(`/custom-fields`);

      setCustomFields(response.data.customFields);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get custom fields');
      }
    }
  };

  const selectedContactsCount = contacts.filter(item => item.selected).length;

  const onContactClick = index => {
    const updatedContacts = [...contacts];
    updatedContacts[index].selected = !updatedContacts[index].selected;
    setContacts(updatedContacts);
  };

  const onHeaderCheckBoxClick = () => {
    if (selectedContactsCount === contacts.length) {
      setContacts(
        contacts.map(contact => ({
          ...contact,
          selected: false
        }))
      );
    } else {
      setContacts(
        contacts.map(contact => ({
          ...contact,
          selected: true
        }))
      );
    }
  };

  const handleUpdateContact = (_updatingContact = {}) => {
    const _customFields = [];

    if (_updatingContact.customFields) {
      Object.keys(_updatingContact.customFields).forEach(field => {
        const customField = customFields.find(
          item => item.fieldName.toLowerCase() === field.toLowerCase()
        );

        if (customField) {
          _customFields.push({
            fieldId: customField.id,
            fieldName: customField.fieldName,
            fieldType: customField.fieldType,
            value: _updatingContact.customFields[field]
          });
        }
      });
    }

    setUpdatingContact({
      ..._updatingContact,
      customFields: _customFields
    });

    setShowUpdateContactModal(true);
  };

  const getArrowStyles = (fieldName, order) => {
    if (sort.fieldName === fieldName && sort.order === order) {
      return {
        color: '#748194'
      };
    } else {
      return {
        color: '#d8e2ef'
      };
    }
  };

  const onSortChange = fieldName => {
    if (sort.fieldName === fieldName) {
      setSort({
        fieldName,
        order: sort.order === ASC ? DESC : ASC
      });
    } else {
      setSort({
        fieldName,
        order: ASC
      });
    }
  };

  useEffect(() => {
    if (user) {
      fetchCustomFields();
    }
  }, [user]);

  useEffect(() => {
    if (limit) {
      setPageCount(Math.ceil(total / limit));
    }
  }, [limit, total]);

  useEffect(() => {
    if (user) {
      fetchContacts();
    }
  }, [limit, page, user, sort, segments, groupId]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
      setPage(1);
    }, 300);

    // Clear the timeout if search changes (before the delay ends)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (user) {
      fetchContacts();
    }
  }, [debouncedSearch]);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <Card className="overflow-hidden">
            <Card.Header className="col-12 border-bottom">
              <h5 className="mb-3">
                <FaAddressBook className="me-2" />
                Contacts
              </h5>
              <div className="d-flex flex-wrap gap-2 justify-content-between">
                <input
                  className="input-value flex-grow-1 mb-3 mb-md-0"
                  label="Value"
                  name="value-0"
                  type="text"
                  placeholder="Search for name, email, phone number"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                <div className="d-flex gap-2">
                  <Button
                    variant="outline-danger"
                    className={`${!selectedContactsCount ? 'disable' : ''}`}
                    disabled={!selectedContactsCount}
                    onClick={() => {
                      const ids = contacts
                        .filter(contact => contact.selected)
                        .map(contact => contact.id);
                      setDeletingContactIds(ids);
                      setShowDeleteContactModal(true);
                    }}
                  >
                    <AiFillDelete /> Delete
                  </Button>
                  {/* <Button
        variant="outline-primary"
        disabled={selectedContactsCount !== 1}
        onClick={() => {
          const _updatingContact = {
            ...contacts.filter((contact) => contact.selected)[0],
          };
          handleUpdateContact(_updatingContact);
        }}
      >
        Edit
      </Button> */}
                  <Button
                    variant="outline-primary"
                    onClick={() => setShowAddContactModal(true)}
                  >
                    <FaPlus /> Create
                  </Button>
                </div>
              </div>
            </Card.Header>

            <Card.Body>
              <Table
                responsive
                style={{
                  height:
                    isFetching || contacts.length < 0
                      ? 'calc(100vh - 250px)'
                      : ''
                }}
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className=" cursor-pointer"
                      onClick={onHeaderCheckBoxClick}
                    >
                      {selectedContactsCount > 0 ? (
                        selectedContactsCount === contacts.length ? (
                          <FaCheckSquare className="text-primary" />
                        ) : (
                          <FaMinusSquare className="text-primary" />
                        )
                      ) : (
                        <FaRegSquare />
                      )}
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('first_name')}
                    >
                      <div className="d-flex" style={{ minWidth: 100 }}>
                        First name
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('first_name', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('first_name', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('last_name')}
                    >
                      <div className="d-flex justify-content-between">
                        Last name
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('last_name', ASC)} />
                          <FaCaretDown
                            style={getArrowStyles('last_name', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('email')}
                    >
                      <div className="d-flex justify-content-between">
                        Email
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp style={getArrowStyles('email', ASC)} />
                          <FaCaretDown style={getArrowStyles('email', DESC)} />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('phone_number')}
                    >
                      <div className="d-flex justify-content-between">
                        Phone number
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('phone_number', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('phone_number', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('text_opt_in')}
                    >
                      <div className="d-flex justify-content-between">
                        Text Opt-in
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('text_opt_in', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('text_opt_in', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer text-center"
                      onClick={() => onSortChange('email_opt_in')}
                    >
                      <div className="d-flex justify-content-between">
                        Email Opt-in
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('email_opt_in', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('email_opt_in', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th scope="col">Custom Fields</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {isFetching ? (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : contacts.length > 0 ? (
                    contacts.map((contact, index) => (
                      <tr
                        key={`contact-${index}`}
                        onClick={() => onContactClick(index)}
                        className={`${contact.selected ? 'bg-light' : ''}`}
                      >
                        <td>
                          {contact.selected ? (
                            <FaCheckSquare className="text-primary fs-9" />
                          ) : (
                            <FaRegSquare className="fs-9" />
                          )}
                        </td>
                        <td>{contact.firstName}</td>
                        <td>{contact.lastName}</td>
                        <td>{contact.email}</td>
                        <td>{contact.phoneNumber}</td>
                        <td className="text-center">
                          {contact.textOptIn ? (
                            <GoCheckCircle className="text-success fs-9" />
                          ) : (
                            <IoIosCloseCircleOutline className="text-danger fs-8" />
                          )}
                        </td>
                        <td className="text-center">
                          {contact.emailOptIn ? (
                            <GoCheckCircle className="text-success fs-9 " />
                          ) : (
                            <IoIosCloseCircleOutline className="text-danger fs-8" />
                          )}
                        </td>
                        <td className="text-capitalize">
                          {contact.customFields &&
                            Object.keys(contact.customFields).map(
                              (field, index) => (
                                <div key={index} className="d-flex gap-2">
                                  <span className="text-capitalize">
                                    {field.replaceAll('_', ' ')}:
                                  </span>

                                  <i>
                                    {contact.customFields[field] &&
                                      contact.customFields[field].toString()}
                                  </i>
                                </div>
                              )
                            )}
                        </td>
                        <td className="px-0">
                          <div className="d-flex gap-2">
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  style={{ position: 'fixed', zIndex: 1000 }}
                                  id="overlay-trigger-example"
                                >
                                  Edit
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="outline-info"
                                onClick={e => {
                                  e.stopPropagation();
                                  handleUpdateContact({ ...contact });
                                }}
                              >
                                <FaEdit />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  style={{ position: 'fixed', zIndex: 1000 }}
                                  id="overlay-trigger-example"
                                >
                                  Delete
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="outline-danger"
                                onClick={e => {
                                  e.stopPropagation();
                                  setShowDeleteContactModal(true);
                                  setDeletingContactIds([contact.id]);
                                }}
                              >
                                <AiFillDelete />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        No contacts found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="position-relative">
                <CustomPagination
                  page={page}
                  pageCount={pageCount}
                  setPage={setPage}
                />
                {contacts.length > 0 && (
                  <Dropdown
                    className="segment-dropdown position-absolute top-0 end-0"
                    style={{ minWidth: '70px' }}
                    onSelect={e => {
                      setLimit(e);
                      setPage(1);
                    }}
                  >
                    <Dropdown.Toggle variant="falcon-default text-capitalize">
                      {limit}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-2">
                      {[10, 20, 50, 100].map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={item}
                          className="text-capitalize"
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddContactModal
        showAddContactModal={showAddContactModal}
        setShowAddContactModal={setShowAddContactModal}
        fetchContacts={fetchContacts}
        customFields={customFields}
      />
      {updatingContact && (
        <UpdateContactModal
          showUpdateContactModal={showUpdateContactModal}
          setShowUpdateContactModal={setShowUpdateContactModal}
          fetchContacts={fetchContacts}
          customFields={customFields}
          updatingContact={updatingContact}
        />
      )}
      {deletingContactIds.length > 0 && (
        <DeleteContactModal
          showDeleteContactModal={showDeleteContactModal}
          setShowDeleteContactModal={setShowDeleteContactModal}
          deletingContactIds={deletingContactIds}
          setDeletingContactIds={setDeletingContactIds}
          fetchContacts={fetchContacts}
        />
      )}
    </>
  );
};

export default ContactsPage;
