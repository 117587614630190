import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Tab, Nav, Form } from 'react-bootstrap';
import EmailEventChart from './EmailEventChart';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';
import { emailEvents } from 'data/common';

const TabTitle = ({ title, value }) => (
  <div className="p-2 pe-4 text-start cursor-pointer">
    <h6 className="text-800 fs-11 text-nowrap text-capitalize ">{title}</h6>
    <h5 className="text-800">{value}</h5>
  </div>
);

const EmailEvent = ({ chartData, className }) => {
  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Card.Header className="border-bottom">
        <h5 className="mb-0 mt-2">Email event</h5>
      </Card.Header>
      <Tab.Container id="EmailEvent-tab" defaultActiveKey={emailEvents[0]}>
        <SimpleBarReact>
          <Card.Header className="p-0 bg-body-tertiary">
            <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
              {emailEvents.map((event, index) => (
                <Nav.Item key={index}>
                  <Nav.Link className="mb-0" eventKey={event}>
                    <TabTitle title={event} value="6.3K" />
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
        </SimpleBarReact>

        <Card.Body>
          <Tab.Content>
            {emailEvents.map((event, index) => (
              <Tab.Pane unmountOnExit eventKey={event} key={index}>
                <EmailEventChart data={chartData[event]} event={event} />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Card.Body>
      </Tab.Container>

      <Card.Footer className="bg-body-tertiary py-2">
        <Row className="g-0 flex-between-center">
          <Col xs="auto">
            <Form.Select size="sm" className="me-2">
              <option>Last Week</option>
              <option>Last Month</option>
              <option>Last Year</option>
            </Form.Select>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

EmailEvent.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.array),
  className: PropTypes.string.isRequired
};

export default EmailEvent;
