import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import EmailEventChart from './SendEmailEventChart';
import classNames from 'classnames';

const SendEmailEvent = ({ chartData, className }) => {
  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Card.Header className="border-bottom">
        <h5 className="mb-0 mt-2">Sends</h5>
        <p className="mb-0 fs-11">The count of successful send requests.</p>
      </Card.Header>

      <Card.Body>
        <EmailEventChart data={chartData['Sent']} event="Sends" />
      </Card.Body>
    </Card>
  );
};

SendEmailEvent.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.array),
  className: PropTypes.string.isRequired
};

export default SendEmailEvent;
