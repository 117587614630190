export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const emailEvents = [
  'Clicks',
  'Opens',
  'Bounce',
  'Complaints',
  'Sent',
  'Delivered'
];

export const operators = {
  '=': 'Equal',
  '!=': 'Not equal',
  '>': 'Greater than',
  '<': 'Less than',
  '>=': 'Greater than or equal',
  '<=': 'Less than or equal',
  contains: 'Contains'
};

export const dateOperators = {
  today: 'Today',
  last7Days: 'Last 7 days',
  last30Days: 'Last 30 days',
  yearToDate: 'Year to date',
  between: 'Between',
  specify: 'Specify the days'
};

export const scheduleTypes = ['hourly', 'daily', 'weekly', 'monthly'];

export const frequency = ['hourly', 'daily', 'weekly', 'monthly'];

export const communicationTypes = ['Email', 'Text'];

export const campaignTypes = ['One-off', 'Drip', 'Recurring'];

export const CampaignType = {
  ONEOFF: 'One-off',
  RECURRING: 'Recurring',
  DRIP: 'Drip'
};

export const campaignObjective = [
  'Promotional',
  'Servicing',
  'Event',
  'Re-engagement',
  'Feedback',
  'Product Launch and Announcement',
  'Dynamic'
];

export const communicationChannels = ['Email', 'SMS'];

export const RecurrencePattern = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
};

export const ExecutionType = {
  MANUAL: 'manual',
  AUTOMATIC: 'automatic'
};

export const RequestStatus = {
  APPROVED: 'Approved',
  DISAPPROVED: 'Disapproved'
};

export const UserStatusType = {
  ACTIVE: 'active',
  DISABLED: 'disabled'
};

export const ProductionAccessStatusType = {
  ACTIVE: 'active',
  INPROGRESS: 'in progress'
};

export const emailType = ['Marketing', 'Transactional'];
