import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip
} from 'react-bootstrap';
import {
  FaCheckSquare,
  FaEdit,
  FaMinusSquare,
  FaRegSquare
} from 'react-icons/fa';
import { FaPlus, FaWandMagicSparkles, FaPeopleArrows } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { selectUser } from 'redux/reducer/auth';
import { useAppSelector } from 'redux/store';
import axiosClient from 'services/axios';
import './styles.scss';

import AddCustomerSegmentModal from 'components/customer-segments/AddCustomerSegmentModal';
import DeleteCustomerSegmentModal from 'components/customer-segments/DeleteCustomerSegmentModal';
import UpdateCustomerSegmentModal from 'components/customer-segments/UpdateCustomerSegmentModal';
import { dateOperators, operators } from 'data/common';
import { AiFillDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import AddCustomerSegmentWithAIModal from 'components/customer-segments/AddCustomerSegmentWithAIModal';
import CustomPagination from 'components/pagination';
import { MdRemoveRedEye } from 'react-icons/md';

const CustomerSegmentsPage = () => {
  const user = useAppSelector(selectUser);
  const [isFetching, setIsFetching] = useState(true);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [customerSegments, setCustomerSegments] = useState([]);
  const [fields, setFields] = useState({});
  const [showAddCustomerSegmentModal, setShowAddCustomerSegmentModal] =
    useState(false);

  const [
    showAddCustomerSegmentWithAIModal,
    setShowAddCustomerSegmentWithAIModal
  ] = useState(false);

  const [showUpdateCustomerSegmentModal, setShowUpdateCustomerSegmentModal] =
    useState(false);

  const [updatingCustomerSegment, setUpdatingCustomerSegment] = useState();

  const [showDeleteCustomerSegmentModal, setShowDeleteCustomerSegmentModal] =
    useState(false);
  const [deletingCustomerSegmentIds, setDeletingCustomerSegmentIds] = useState(
    []
  );
  const [editable, setEditable] = useState(false);

  const fetchCustomerSegments = async () => {
    setIsFetching(true);
    try {
      const response = await axiosClient.get(
        `/customer-segments?page=${page}&limit=${limit}&search=${search}`
      );
      const _customerSegments = response.data.customerSegments.map(
        customerSegment => ({
          ...customerSegment,
          criteria: customerSegment.criteria || [],
          selected: false
        })
      );
      setCustomerSegments(_customerSegments);
      setTotal(response.data.total);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get customer segments');
      }
    }
    setIsFetching(false);
  };

  const fetchFields = async () => {
    try {
      const response = await axiosClient.get('/customer-segments/fields');
      setFields(response.data.fields);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get fields');
      }
    }
  };

  const selectedCustomerSegmentsCount = customerSegments.filter(
    item => item.selected
  ).length;

  const onCustomerSegmentsClick = index => {
    const updatedCustomerSegments = [...customerSegments];
    updatedCustomerSegments[index].selected =
      !updatedCustomerSegments[index].selected;
    setCustomerSegments(updatedCustomerSegments);
  };

  const onHeaderCheckBoxClick = () => {
    if (selectedCustomerSegmentsCount === customerSegments.length) {
      setCustomerSegments(
        customerSegments.map(customerSegment => ({
          ...customerSegment,
          selected: false
        }))
      );
    } else {
      setCustomerSegments(
        customerSegments.map(customerSegment => ({
          ...customerSegment,
          selected: true
        }))
      );
    }
  };

  useEffect(() => {
    if (user) {
      fetchFields();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchCustomerSegments();
    }
  }, [limit, page, user]);

  useEffect(() => {
    if (limit) {
      setPageCount(Math.ceil(total / limit));
    }
  }, [limit, total]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
      setPage(1);
    }, 300);

    // Clear the timeout if search changes (before the delay ends)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (user) {
      fetchCustomerSegments();
    }
  }, [debouncedSearch]);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <Card className="overflow-hidden">
            <Card.Header className="col-12 border-bottom">
              <h5 className="mb-3">
                <FaPeopleArrows className="me-2" />
                Customer Segments
              </h5>
              <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
                <input
                  className="input-value flex-grow-1 mb-3 mb-md-0"
                  label="Value"
                  name="value-0"
                  type="text"
                  placeholder="Search for name"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                <div className="d-flex  gap-2">
                  <Button
                    variant="outline-danger"
                    className={`${
                      !selectedCustomerSegmentsCount ? 'disable' : ''
                    }`}
                    disabled={!selectedCustomerSegmentsCount}
                    onClick={() => {
                      const ids = customerSegments
                        .filter(customerSegment => customerSegment.selected)
                        .map(customerSegment => customerSegment.id);
                      setDeletingCustomerSegmentIds(ids);
                      setShowDeleteCustomerSegmentModal(true);
                    }}
                  >
                    <AiFillDelete /> Delete
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => setShowAddCustomerSegmentModal(true)}
                    disabled={isFetching}
                  >
                    <FaPlus /> Create
                  </Button>
                  <Button
                    variant="outline-info"
                    onClick={() => setShowAddCustomerSegmentWithAIModal(true)}
                    disabled={isFetching}
                  >
                    <FaWandMagicSparkles />
                    Generate with AI
                  </Button>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Table
                responsive
                style={{
                  height:
                    isFetching || customerSegments.length < 0
                      ? 'calc(100vh - 270px)'
                      : ''
                }}
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className=" cursor-pointer"
                      onClick={onHeaderCheckBoxClick}
                    >
                      {selectedCustomerSegmentsCount > 0 ? (
                        selectedCustomerSegmentsCount ===
                        customerSegments.length ? (
                          <FaCheckSquare className="text-primary" />
                        ) : (
                          <FaMinusSquare className="text-primary" />
                        )
                      ) : (
                        <FaRegSquare />
                      )}
                    </th>
                    <th scope="col">Segment name</th>
                    <th scope="col">Criteria</th>
                    <th scope="col" width="50"></th>
                  </tr>
                </thead>
                <tbody>
                  {isFetching ? (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : customerSegments.length > 0 ? (
                    customerSegments.map((customerSegment, index) => (
                      <tr
                        key={`custom-field-${index}`}
                        onClick={() => onCustomerSegmentsClick(index)}
                        className={`${
                          customerSegment.selected ? 'bg-light' : ''
                        }`}
                      >
                        <td>
                          {customerSegment.selected ? (
                            <FaCheckSquare className="text-primary fs-9" />
                          ) : (
                            <FaRegSquare className="fs-9" />
                          )}
                        </td>
                        <td>
                          <Link
                            to={`/contacts?segments=${decodeURIComponent(
                              JSON.stringify([customerSegment.criteria])
                            )}`}
                          >
                            {customerSegment.segmentName}
                          </Link>
                        </td>
                        <td className="text-capitalize">
                          {customerSegment.criteria &&
                            customerSegment.criteria.map((criteria, index) => (
                              <div key={index} className="d-flex gap-2">
                                {criteria.andOr && !!index && (
                                  <strong>{criteria.andOr}</strong>
                                )}
                                <span className="text-capitalize">
                                  {criteria.field?.replaceAll('_', ' ')}
                                </span>
                                <i className="text-lowercase">
                                  {
                                    { ...operators, ...dateOperators }[
                                      criteria.operator
                                    ]
                                  }
                                </i>
                                <span>
                                  {typeof criteria.value === 'object'
                                    ? criteria.operator === 'between' &&
                                      `${criteria.value.fromDate} and ${criteria.value.toDate}`
                                    : criteria.value?.toString()}
                                </span>
                              </div>
                            ))}
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  style={{ position: 'fixed', zIndex: 1000 }}
                                  id="overlay-trigger-example"
                                >
                                  View
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="outline-warning"
                                onClick={e => {
                                  e.stopPropagation();
                                  setUpdatingCustomerSegment({
                                    ...customerSegment
                                  });
                                  setShowUpdateCustomerSegmentModal(true);
                                  setEditable(false);
                                }}
                              >
                                <MdRemoveRedEye />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  style={{ position: 'fixed', zIndex: 1000 }}
                                  id="overlay-trigger-example"
                                >
                                  Edit
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="outline-info"
                                onClick={e => {
                                  e.stopPropagation();
                                  setUpdatingCustomerSegment({
                                    ...customerSegment
                                  });
                                  setShowUpdateCustomerSegmentModal(true);
                                  setEditable(true);
                                }}
                              >
                                <FaEdit />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  style={{ position: 'fixed', zIndex: 1000 }}
                                  id="overlay-trigger-example"
                                >
                                  Delete
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="outline-danger"
                                onClick={e => {
                                  e.stopPropagation();
                                  setDeletingCustomerSegmentIds([
                                    customerSegment.id
                                  ]);
                                  setShowDeleteCustomerSegmentModal(true);
                                }}
                              >
                                <AiFillDelete />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="6"
                        className="text-center align-items-center align-content-center"
                      >
                        No customer segment found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="position-relative">
                <CustomPagination
                  page={page}
                  pageCount={pageCount}
                  setPage={setPage}
                />
                {customerSegments.length > 0 && (
                  <Dropdown
                    className="segment-dropdown position-absolute top-0 end-0"
                    style={{ minWidth: '70px' }}
                    onSelect={e => {
                      setLimit(e);
                      setPage(1);
                    }}
                  >
                    <Dropdown.Toggle variant="falcon-default text-capitalize">
                      {limit}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-2">
                      {[10, 20, 50, 100].map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={item}
                          className="text-capitalize"
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddCustomerSegmentModal
        user={user}
        fields={fields}
        showAddCustomerSegmentModal={showAddCustomerSegmentModal}
        setShowAddCustomerSegmentModal={setShowAddCustomerSegmentModal}
        fetchCustomerSegments={fetchCustomerSegments}
      />
      <AddCustomerSegmentWithAIModal
        user={user}
        fields={fields}
        showAddCustomerSegmentWithAIModal={showAddCustomerSegmentWithAIModal}
        setShowAddCustomerSegmentWithAIModal={
          setShowAddCustomerSegmentWithAIModal
        }
        fetchCustomerSegments={fetchCustomerSegments}
      />
      {updatingCustomerSegment && (
        <UpdateCustomerSegmentModal
          user={user}
          fields={fields}
          showUpdateCustomerSegmentModal={showUpdateCustomerSegmentModal}
          setShowUpdateCustomerSegmentModal={setShowUpdateCustomerSegmentModal}
          fetchCustomerSegments={fetchCustomerSegments}
          updatingCustomerSegment={updatingCustomerSegment}
          editable={editable}
        />
      )}
      {deletingCustomerSegmentIds.length > 0 && (
        <DeleteCustomerSegmentModal
          showDeleteCustomerSegmentModal={showDeleteCustomerSegmentModal}
          setShowDeleteCustomerSegmentModal={setShowDeleteCustomerSegmentModal}
          deletingCustomerSegmentIds={deletingCustomerSegmentIds}
          setDeletingCustomerSegmentIds={setDeletingCustomerSegmentIds}
          fetchCustomerSegments={fetchCustomerSegments}
        />
      )}
    </>
  );
};

export default CustomerSegmentsPage;
