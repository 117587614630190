import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import DeleteTemplateModal from 'components/templates/DeleteTemplateModal';
import Lottie from 'lottie-react';
import { AiFillDelete } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';
import { FaPlus, FaPalette } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import axiosClient from 'services/axios';
import root from 'react-shadow';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { categories } from 'data/categories';

const UserEmailTemplatesPage = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
  const [deletingTemplate, setDeletingTemplate] = useState();
  const [filter, setFilter] = useState({
    name: '',
    category: ''
  });

  const fetchTemplates = async () => {
    setIsFetching(true);
    try {
      const response = await axiosClient.get(
        `/templates/user?type=email&name=${filter.name}&category=${filter.category}`
      );
      setTemplates(response.data);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to fetch templates');
      }
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetchTemplates();
  }, [filter]);

  return (
    <div>
      {/* Header with Icon */}
      <h5 className="mb-3">
        <FaPalette className="me-2" />
        User Email Templates
      </h5>

      {/* Container for Inputs and Button */}
      <div className="d-flex flex-wrap mb-3 gap-3 align-items-center">
        {/* Search Input */}
        <input
          className="input-value flex-grow-1 mb-3 mb-md-0"
          label="Value"
          name="value-0"
          type="text"
          placeholder="Search for template name"
          value={filter.name}
          onChange={e =>
            setFilter({
              ...filter,
              name: e.target.value
            })
          }
        />

        {/* Category Select */}
        <Form.Select
          type="select"
          value={filter.category}
          onChange={e => {
            setFilter({
              ...filter,
              category: e.target.value
            });
          }}
          name="category"
          className="mb-3 mb-md-0"
          style={{ width: '200px' }}
        >
          <option value="">Select category</option>
          {categories.map(option => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </Form.Select>

        {/* Create Button */}
        <div className="ms-auto mb-3 mb-md-0">
          <Link to="/templates/email-template/create">
            <Button variant="outline-primary">
              <FaPlus className="me-1" />
              Create
            </Button>
          </Link>
        </div>
      </div>

      <Row className="">
        {isFetching ? (
          <Col
            xs={12}
            className="d-flex flex-column justify-content-center align-items-center min-vh-75"
          >
            <Lottie
              animationData={infiniteLoop}
              loop={true}
              style={{ height: '200px', width: '200px' }}
            />
            <h3 className="text-center">Loading...</h3>
          </Col>
        ) : templates.length > 0 ? (
          templates.map((template, index) => (
            <Col
              md="12"
              xl="3"
              key={`template-${index}`}
              className="template my-2"
            >
              <Card>
                <Card.Header className="col-12 border-bottom">
                  <div className="d-flex flex-wrap gap-2 justify-content-between">
                    <Card.Title
                      as="h5"
                      id="contained-modal-title-vcenter"
                      className="mb-0"
                    >
                      {template.name}{' '}
                      {template.isPublic && (
                        <FontAwesomeIcon
                          icon="star"
                          size="xs"
                          color="#fdd600"
                        />
                      )}
                    </Card.Title>
                    <div className="d-flex gap-2">
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            style={{ position: 'fixed', zIndex: 1000 }}
                            id="overlay-trigger-example"
                          >
                            Edit
                          </Tooltip>
                        }
                      >
                        <Link to={`/templates/email-template/${template.id}`}>
                          <Button variant="outline-info">
                            <FaEdit />
                          </Button>
                        </Link>
                      </OverlayTrigger>

                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            style={{ position: 'fixed', zIndex: 1000 }}
                            id="overlay-trigger-example"
                          >
                            Delete
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="outline-danger"
                          onClick={() => {
                            setDeletingTemplate(template);
                            setShowDeleteTemplateModal(true);
                          }}
                        >
                          <AiFillDelete />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <span className="fs-11">Category: {template.category}</span>
                </Card.Header>
                <Card.Body style={{ height: 580, overflowY: 'auto' }}>
                  <root.div className="quote">
                    <style>{template.content?.css}</style>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: template.content?.html
                      }}
                    />
                  </root.div>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <Col xs={12} className="text-center">
            <h5>No templates found</h5>
          </Col>
        )}
      </Row>
      {deletingTemplate && (
        <DeleteTemplateModal
          showDeleteTemplateModal={showDeleteTemplateModal}
          setShowDeleteTemplateModal={setShowDeleteTemplateModal}
          deletingTemplate={deletingTemplate}
          setDeletingTemplate={setDeletingTemplate}
          fetchTemplates={fetchTemplates}
        />
      )}
    </div>
  );
};

export default UserEmailTemplatesPage;
