import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import OpenEmailEventChart from './OpenEmailEventChart';

const OpenEmailEvent = ({ chartData, className }) => {
  const openedEmailPercentage = [];
  chartData['Sent'].forEach((item, index) => {
    const percentage = (chartData['Opens'][index] / item) * 100;
    console.log(percentage);
    openedEmailPercentage.push(percentage.toFixed(1));
  });
  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Card.Header className="border-bottom">
        <h5 className="mb-0 mt-2">Opens</h5>
        <p className="mb-0 fs-11">
          The percentage of emails that have been opened
        </p>
      </Card.Header>

      <Card.Body>
        <OpenEmailEventChart data={openedEmailPercentage} event="Clicks" />
      </Card.Body>
    </Card>
  );
};

OpenEmailEvent.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.array),
  className: PropTypes.string.isRequired
};

export default OpenEmailEvent;
