import './styles.scss';

import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip
} from 'react-bootstrap';
import {
  FaCaretDown,
  FaCaretUp,
  FaCheckSquare,
  FaEdit,
  FaMinusSquare,
  FaRegSquare,
  FaPuzzlePiece
} from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';

import { AiFillDelete } from 'react-icons/ai';
import { toast } from 'react-toastify';
import axiosClient from 'services/axios';

import AddCustomFieldModal from 'components/custom-field/AddCustomFieldModal';
import DeleteCustomFieldModal from 'components/custom-field/DeleteCustomFieldModal';
import UpdateCustomFieldModal from 'components/custom-field/UpdateCustomFieldModal';
import CustomPagination from 'components/pagination';
import { selectUser } from 'redux/reducer/auth';
import { useAppSelector } from 'redux/store';

const ASC = 'ASC';
const DESC = 'DESC';

const CustomFieldsPage = () => {
  const user = useAppSelector(selectUser);
  const [isFetching, setIsFetching] = useState(true);

  const [customFields, setCustomFields] = useState([]);
  const [showAddCustomFieldModal, setShowAddCustomFieldModal] = useState(false);

  const [showUpdateCustomFieldModal, setShowUpdateCustomFieldModal] =
    useState(false);
  const [updatingCustomField, setUpdatingCustomField] = useState();

  const [showDeleteCustomFieldModal, setShowDeleteCustomFieldModal] =
    useState(false);
  const [deletingCustomFieldIds, setDeletingCustomFieldIds] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  const [sort, setSort] = useState({
    fieldName: 'field_name',
    order: ASC
  });

  const selectedCustomFieldsCount = customFields.filter(
    item => item.selected
  ).length;

  const onCustomFieldsClick = index => {
    const updatedCustomFields = [...customFields];
    updatedCustomFields[index].selected = !updatedCustomFields[index].selected;
    setCustomFields(updatedCustomFields);
  };

  const onHeaderCheckBoxClick = () => {
    if (selectedCustomFieldsCount === customFields.length) {
      setCustomFields(
        customFields.map(customField => ({
          ...customField,
          selected: false
        }))
      );
    } else {
      setCustomFields(
        customFields.map(customField => ({
          ...customField,
          selected: true
        }))
      );
    }
  };

  const fetchCustomFields = async () => {
    setIsFetching(true);

    try {
      const response = await axiosClient.get(
        `/custom-fields?page=${page}&limit=${limit}&search=${search}&sort=${sort.fieldName}:${sort.order}`
      );
      const _customFields = response.data.customFields.map(customField => ({
        ...customField,
        selected: false
      }));
      setCustomFields(_customFields);
      setTotal(response.data.total);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Error to get custom fields');
      }
    }
    setIsFetching(false);
  };

  const getArrowStyles = (fieldName, order) => {
    if (sort.fieldName === fieldName && sort.order === order) {
      return {
        color: '#748194'
      };
    } else {
      return {
        color: '#d8e2ef'
      };
    }
  };

  const onSortChange = fieldName => {
    if (sort.fieldName === fieldName) {
      setSort({
        fieldName,
        order: sort.order === ASC ? DESC : ASC
      });
    } else {
      setSort({
        fieldName,
        order: ASC
      });
    }
  };

  useEffect(() => {
    if (limit) {
      setPageCount(Math.ceil(total / limit));
    }
  }, [limit, total]);

  useEffect(() => {
    if (user) {
      fetchCustomFields();
    }
  }, [limit, page, user, sort]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
      setPage(1);
    }, 300);

    // Clear the timeout if search changes (before the delay ends)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (user) {
      fetchCustomFields();
    }
  }, [debouncedSearch]);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <Card className="overflow-hidden">
            <Card.Header className="col-12 border-bottom">
              <h5 className="mb-3">
                <FaPuzzlePiece className="me-2" />
                Custom Fields
              </h5>
              <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
                <input
                  className="input-value flex-grow-1 mb-3 mb-md-0"
                  label="Value"
                  name="value-0"
                  type="text"
                  placeholder="Search for field name, type"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                <div className="d-flex gap-2">
                  <Button
                    variant="outline-danger"
                    className={`${!selectedCustomFieldsCount ? 'disable' : ''}`}
                    disabled={!selectedCustomFieldsCount}
                    onClick={() => {
                      const ids = customFields
                        .filter(customField => customField.selected)
                        .map(customField => customField.id);
                      setDeletingCustomFieldIds(ids);
                      setShowDeleteCustomFieldModal(true);
                    }}
                  >
                    <AiFillDelete /> Delete
                  </Button>
                  {/* Leave the commented code as is */}
                  {/* <Button
        variant="outline-primary"
        disabled={selectedCustomFieldsCount !== 1}
        onClick={() => {
          const _updatingCustomField = {
            ...customFields.filter(
              (customField) => customField.selected
            )[0],
          };
          setUpdatingCustomField(_updatingCustomField);
          setShowUpdateCustomFieldModal(true);
        }}
      >
        Edit
      </Button> */}
                  <Button
                    variant="outline-primary"
                    onClick={() => setShowAddCustomFieldModal(true)}
                  >
                    <FaPlus /> Create
                  </Button>
                </div>
              </div>
            </Card.Header>

            <Card.Body>
              <Table
                responsive
                style={{
                  height:
                    isFetching || customFields.length < 0
                      ? 'calc(100vh - 255px)'
                      : ''
                }}
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className=" cursor-pointer"
                      onClick={onHeaderCheckBoxClick}
                    >
                      {selectedCustomFieldsCount > 0 ? (
                        selectedCustomFieldsCount === customFields.length ? (
                          <FaCheckSquare className="text-primary" />
                        ) : (
                          <FaMinusSquare className="text-primary" />
                        )
                      ) : (
                        <FaRegSquare />
                      )}
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('field_name')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 100 }}
                      >
                        Field name
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('field_name', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('field_name', DESC)}
                          />
                        </span>
                      </div>
                    </th>

                    <th
                      scope="col"
                      className="cursor-pointer"
                      onClick={() => onSortChange('field_type')}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ minWidth: 100 }}
                      >
                        Type
                        <span className="d-flex flex-column fs-11">
                          <FaCaretUp
                            style={getArrowStyles('field_type', ASC)}
                          />
                          <FaCaretDown
                            style={getArrowStyles('field_type', DESC)}
                          />
                        </span>
                      </div>
                    </th>
                    <th width="50" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {isFetching ? (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : customFields.length > 0 ? (
                    customFields.map((customField, index) => (
                      <tr
                        key={`custom-field-${index}`}
                        onClick={() => onCustomFieldsClick(index)}
                        className={`${customField.selected ? 'bg-light' : ''}`}
                      >
                        <td>
                          {customField.selected ? (
                            <FaCheckSquare className="text-primary fs-9" />
                          ) : (
                            <FaRegSquare className="fs-9" />
                          )}
                        </td>
                        <td>{customField.fieldName}</td>
                        <td className="text-capitalize">
                          {customField.fieldType}
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  style={{ position: 'fixed', zIndex: 1000 }}
                                  id="overlay-trigger-example"
                                >
                                  Edit
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="outline-info"
                                onClick={e => {
                                  e.stopPropagation();
                                  setUpdatingCustomField(customField);
                                  setShowUpdateCustomFieldModal(true);
                                }}
                              >
                                <FaEdit />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  style={{ position: 'fixed', zIndex: 1000 }}
                                  id="overlay-trigger-example"
                                >
                                  Delete
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="outline-danger"
                                onClick={e => {
                                  e.stopPropagation();
                                  setShowDeleteCustomFieldModal(true);
                                  setDeletingCustomFieldIds([customField.id]);
                                }}
                              >
                                <AiFillDelete />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center align-items-center align-content-center"
                      >
                        No custom fields found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="position-relative">
                <CustomPagination
                  page={page}
                  pageCount={pageCount}
                  setPage={setPage}
                />
                {customFields.length > 0 && (
                  <Dropdown
                    className="segment-dropdown position-absolute top-0 end-0"
                    style={{ minWidth: '70px' }}
                    onSelect={e => {
                      setLimit(e);
                      setPage(1);
                    }}
                  >
                    <Dropdown.Toggle variant="falcon-default text-capitalize">
                      {limit}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="py-2">
                      {[10, 20, 50, 100].map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={item}
                          className="text-capitalize"
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddCustomFieldModal
        showAddCustomFieldModal={showAddCustomFieldModal}
        setShowAddCustomFieldModal={setShowAddCustomFieldModal}
        fetchCustomFields={fetchCustomFields}
      />
      {updatingCustomField && (
        <UpdateCustomFieldModal
          showUpdateCustomFieldModal={showUpdateCustomFieldModal}
          setShowUpdateCustomFieldModal={setShowUpdateCustomFieldModal}
          updatingCustomField={updatingCustomField}
          fetchCustomFields={fetchCustomFields}
        />
      )}
      {deletingCustomFieldIds.length > 0 && (
        <DeleteCustomFieldModal
          showDeleteCustomFieldModal={showDeleteCustomFieldModal}
          setShowDeleteCustomFieldModal={setShowDeleteCustomFieldModal}
          deletingCustomFieldIds={deletingCustomFieldIds}
          setDeletingCustomFieldIds={setDeletingCustomFieldIds}
          fetchCustomFields={fetchCustomFields}
        />
      )}
    </>
  );
};

export default CustomFieldsPage;
