import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import Divider from 'components/common/Divider';
import WizardInput from 'components/wizard/WizardInput';
import axiosClient from 'services/axios';
import { toast } from 'react-toastify';
import { UserStatusType, emailType } from 'data/common';
import { businessTypes } from 'data/businessTypes';

const UserModal = ({
  showUserModal,
  setShowUserModal,
  fetchUsers,
  userDetail,
  mode
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      fullName: '',
      website: '',
      email: '',
      phoneNumber: '',
      tierPlan: '',
      isVerified: '',
      status: '',
      businessName: '',
      businessAddress: '',
      businessType: '',
      otherBusinessType: '',
      state: '',
      zipCode: '',
      city: '',
      awsAccountNumber: '',
      sesSetupComplete: '',
      emailType: '',
      emailUseCase: '',
      emailSendingEnabled: ''
    }
  });

  const [isOtherSelected, setIsOtherSelected] = React.useState(false);
  const handleBusinessTypeChange = event => {
    setIsOtherSelected(event.target.value === 'Other');
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    reset();
  }, [showUserModal]);

  const handleUpdateUser = async data => {
    setLoading(true);
    try {
      const finalData = {
        ...data,
        businessType:
          data.businessType === 'Other'
            ? data.otherBusinessType
            : data.businessType
      };
      await axiosClient.put(`/users/${userDetail.id}`, finalData);
      fetchUsers();
      toast.success('User updated successfully');
      setShowUserModal(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || 'User update failed');
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = data => {
    if (mode === 'EDIT') {
      handleUpdateUser(data);
    }
  };

  useEffect(() => {
    if (userDetail && mode !== 'ADD') {
      const transformData = {
        ...userDetail
      };

      Object.keys(transformData).forEach(item => {
        setValue(item, transformData[item]);
      });
    }
  }, [userDetail]);

  useEffect(() => {
    if (userDetail && mode !== 'ADD') {
      const transformData = {
        ...userDetail
      };

      if (!businessTypes.includes(userDetail.businessType)) {
        transformData.businessType = 'Other';
        transformData.otherBusinessType = userDetail.businessType;
        setIsOtherSelected(true);
      } else {
        setIsOtherSelected(false);
      }

      Object.keys(transformData).forEach(item => {
        setValue(item, transformData[item]);
      });
    }
  }, [userDetail]);

  return (
    <Modal
      show={showUserModal}
      onHide={() => {
        setShowUserModal(false);
      }}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {mode === 'EDIT' ? 'Update User' : 'Add User'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading ? (
          <Row className="justify-content-center mb-3 g-3">
            <Col lg={4} className="d-flex justify-content-center">
              <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '120px', width: '120px' }}
              />
            </Col>
            <h3 className="text-center">Loading...</h3>
          </Row>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Col>
              <WizardInput
                label="Full name"
                name="fullName"
                errors={errors}
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{
                  ...register('fullName', {
                    required: 'Full name is required'
                  })
                }}
              />
              <WizardInput
                type="text"
                label="Website"
                name="website"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('website', {
                    required: 'Website is required',
                    pattern: {
                      value: /^(https):\/\/[^ "]+$/i,
                      message: 'Website URL must be valid'
                    }
                  })
                }}
              />
              <WizardInput
                type="email"
                errors={errors}
                label="Email*"
                name="email"
                formGroupProps={{ className: 'mb-3' }}
                formControlProps={{
                  ...register('email', {
                    required: 'Email field is required',
                    pattern: {
                      value:
                        /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                      message: 'Email must be valid'
                    }
                  }),
                  disabled: true
                }}
              />
              <WizardInput
                type="text"
                label="Phone number"
                name="phoneNumber"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('phoneNumber', {
                    required: 'Phone number is required'
                  })
                }}
              />
              <WizardInput
                type="select"
                label="Tier plan"
                name="tierPlan"
                placeholder="Select tier plan..."
                errors={errors}
                options={['Tier 1', 'Tier 2', 'Tier 3']}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('tierPlan', {
                    required: 'Tier plan is required'
                  })
                }}
              />
              {/* <Controller
                  name="isVerified"
                  control={control}
                  render={({ field }) => (
                    <Form.Check
                      type="checkbox"
                      id="isVerified"
                      className="mb-0"
                    >
                      <Form.Check.Input
                        type="checkbox"
                        {...field}
                        checked={field.value || false}
                        disabled
                      />
                      <Form.Check.Label className="mb-0 text-700">
                        Is Verified
                      </Form.Check.Label>
                    </Form.Check>
                  )}
                /> */}
              <WizardInput
                type="text"
                label="Business name"
                name="businessName"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('businessName', {
                    required: 'Business name is required'
                  })
                }}
              />
              <WizardInput
                type="textarea"
                label="Business Address"
                name="businessAddress"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('businessAddress', {
                    required: 'Business address is required'
                  })
                }}
              />
              <Row>
                <WizardInput
                  type="select"
                  label="Business Type"
                  name="businessType"
                  placeholder="Select business type..."
                  errors={errors}
                  options={businessTypes}
                  formGroupProps={{
                    className: `mb-3 ${isOtherSelected ? 'w-50' : ''}`
                  }}
                  formControlProps={{
                    ...register('businessType', {
                      required: 'Business type is required'
                    }),
                    onChange: e => {
                      handleBusinessTypeChange(e);
                    }
                  }}
                />
                {isOtherSelected && (
                  <WizardInput
                    type="text"
                    label="Specify Other Business Type"
                    name="otherBusinessType"
                    errors={errors}
                    formGroupProps={{
                      className: `mb-3 ${isOtherSelected ? 'w-50' : ''}`
                    }}
                    formControlProps={{
                      className: 'input-spin-none',
                      ...register('otherBusinessType', {
                        required: 'Please specify your business type'
                      })
                    }}
                  />
                )}
              </Row>
              <WizardInput
                type="text"
                label="State"
                name="state"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('state', {
                    required: 'State is required'
                  })
                }}
              />
              <WizardInput
                type="text"
                label="Zip Code"
                name="zipCode"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('zipCode', {
                    required: 'Zip Code is required',
                    pattern: {
                      value: /^\d{5}(-\d{4})?$/,
                      message: 'Zip Code must be valid'
                    }
                  })
                }}
              />
              <WizardInput
                type="text"
                label="City"
                name="city"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('city', {
                    required: 'City is required'
                  })
                }}
              />
              <WizardInput
                type="text"
                label="AWS Account Number"
                name="awsAccountNumber"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  className: 'input-spin-none',
                  ...register('awsAccountNumber'),
                  disabled: true
                }}
              />
              {/* <Controller
                    name="sesSetupComplete"
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        type="checkbox"
                        id="sesSetupComplete"
                        className="mb-0"
                      >
                        <Form.Check.Input
                          type="checkbox"
                          {...field}
                          checked={field.value || false}
                          disabled
                        />
                        <Form.Check.Label className="mb-0 text-700">
                          Ses Setup Complete
                        </Form.Check.Label>
                      </Form.Check>
                    )}
                  /> */}
              <WizardInput
                type="select"
                label="Email type"
                name="emailType"
                placeholder="Select email type"
                errors={errors}
                options={emailType}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('emailType', {
                    required: 'Email type is required'
                  })
                }}
              />
              <WizardInput
                type="textarea"
                label="Use case"
                name="emailUseCase"
                placeholder="Please describe the purpose of this email service"
                errors={errors}
                formGroupProps={{
                  className: 'mb-3'
                }}
                formControlProps={{
                  ...register('emailUseCase', {
                    required: 'Use case is required'
                  })
                }}
              />
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Form.Group controlId="status" className="mb-0">
                    <Form.Label className="mb-0 text-700">Status</Form.Label>
                    <div className="d-flex gap-4">
                      <Form.Check
                        type="radio"
                        label="Active"
                        id="statusActive"
                        {...field}
                        value={UserStatusType.ACTIVE}
                        checked={field.value === UserStatusType.ACTIVE}
                        className="mb-1"
                      />
                      <Form.Check
                        type="radio"
                        label="Disabled"
                        id="statusDisabled"
                        {...field}
                        value={UserStatusType.DISABLED}
                        checked={field.value === UserStatusType.DISABLED}
                        className="mb-0"
                      />
                    </div>
                  </Form.Group>
                )}
              />
              <Controller
                name="emailSendingEnabled"
                control={control}
                render={({ field }) => (
                  <Form.Group controlId="emailSendingEnabled" className="mb-0">
                    <Form.Label className="mb-0 text-700">
                      Email Sending Enabled
                    </Form.Label>
                    <div className="d-flex gap-4">
                      <Form.Check
                        type="radio"
                        label="True"
                        id="emailEnabled"
                        {...field}
                        value={true}
                        checked={field.value === true}
                        className="mb-1"
                        onChange={() => field.onChange(true)}
                      />
                      <Form.Check
                        type="radio"
                        label="False"
                        id="emailDisabled"
                        {...field}
                        value={false}
                        checked={field.value === false}
                        className="mb-0"
                        onChange={() => field.onChange(false)}
                      />
                    </div>
                  </Form.Group>
                )}
              />
              <Controller
                name="textSendingEnabled"
                control={control}
                render={({ field }) => (
                  <Form.Group controlId="textSendingEnabled" className="mb-0">
                    <Form.Label className="mb-0 text-700">
                      Text Sending Enabled
                    </Form.Label>
                    <div className="d-flex gap-4">
                      <Form.Check
                        type="radio"
                        label="True"
                        id="textEnabled"
                        {...field}
                        value={true}
                        checked={field.value === true}
                        className="mb-1"
                        onChange={() => field.onChange(true)}
                      />
                      <Form.Check
                        type="radio"
                        label="False"
                        id="textDisabled"
                        {...field}
                        value={false}
                        checked={field.value === false}
                        className="mb-0"
                        onChange={() => field.onChange(false)}
                      />
                    </div>
                  </Form.Group>
                )}
              />
            </Col>
          </Form>
        )}
      </Modal.Body>
      <Divider />
      <div className="col-12 d-flex flex-column gap-3 justify-content-center align-items-center px-4 mb-3">
        <Button
          disabled={loading}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

UserModal.propTypes = {
  showUserModal: PropTypes.bool,
  setShowUserModal: PropTypes.func,
  fetchUsers: PropTypes.func,
  userDetail: PropTypes.object,
  mode: PropTypes.oneOf(['VIEW', 'ADD', 'EDIT'])
};

export default UserModal;
