import DailyEmailUsage from 'components/dashboard/DailyEmailUsage';
import SendingStatistics from 'components/dashboard/SendingStatistics';
import SentEmailVolume from 'components/dashboard/SentEmailVolume';
import BounceEmailEvent from 'components/dashboard/bounce-email-event/BounceEmailEvent';
import ClickEmailEvent from 'components/dashboard/click-email-event/ClickEmailEvent';
import ComplaintsEmailEvent from 'components/dashboard/complaints-email-event/ComplaintsEmailEvent';
import DeliveredEmailEvent from 'components/dashboard/delivered-email-event/DeliveredEmailEvent';
import EmailEvent from 'components/dashboard/email-event/EmailEvent';
import OpenEmailEvent from 'components/dashboard/open-email-event/OpenEmailEvent';
import SendEmailEvent from 'components/dashboard/send-email-event/SendEmailEvent';
import { emailEventsChart } from 'data/dashboard/analytics';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const MainAccountDashboard = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={12}>
          <SentEmailVolume />
        </Col>
        <Col md={12}>
          <DailyEmailUsage />
        </Col>
        <Col md={12}>
          <EmailEvent chartData={emailEventsChart} className="mb-3" />
        </Col>
        <Col md={12}>
          <SendingStatistics />
        </Col>
        <Col md={12} xxl={6}>
          <SendEmailEvent chartData={emailEventsChart} className="mb-3" />
        </Col>
        <Col md={12} xxl={6}>
          <OpenEmailEvent chartData={emailEventsChart} className="mb-3" />
        </Col>
        <Col md={12} xxl={6}>
          <ClickEmailEvent chartData={emailEventsChart} className="mb-3" />
        </Col>
        <Col md={12} xxl={6}>
          <DeliveredEmailEvent chartData={emailEventsChart} className="mb-3" />
        </Col>
        <Col md={12} xxl={6}>
          <ComplaintsEmailEvent chartData={emailEventsChart} className="mb-3" />
        </Col>
        <Col md={12} xxl={6}>
          <BounceEmailEvent chartData={emailEventsChart} className="mb-3" />
        </Col>
      </Row>
    </>
  );
};

export default MainAccountDashboard;
