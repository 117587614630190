import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import BounceEmailEventChart from './BounceEmailEventChart';

const BounceEmailEvent = ({ chartData, className }) => {
  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Card.Header className="border-bottom">
        <h5 className="mb-0 mt-2">Bounce</h5>
        <p className="mb-0 fs-11">
          The percentage of emails that resulted in a hard bounce.
        </p>
      </Card.Header>

      <Card.Body>
        <BounceEmailEventChart data={chartData['Sent']} event="Sends" />
      </Card.Body>
    </Card>
  );
};

BounceEmailEvent.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.array),
  className: PropTypes.string.isRequired
};

export default BounceEmailEvent;
