import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import ClickEmailEventChart from './ClickEmailEventChart';
import classNames from 'classnames';

const ClickEmailEvent = ({ chartData, className }) => {
  const clickedEmailPercentage = [];
  chartData['Sent'].forEach((item, index) => {
    const percentage = (chartData['Clicks'][index] / item) * 100;
    clickedEmailPercentage.push(percentage.toFixed(1));
  });
  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Card.Header className="border-bottom">
        <h5 className="mb-0 mt-2">Clicks</h5>
        <p className="mb-0 fs-11">
          The percentage of emails that have been clicked
        </p>
      </Card.Header>

      <Card.Body>
        <ClickEmailEventChart data={clickedEmailPercentage} event="Clicks" />
      </Card.Body>
    </Card>
  );
};

ClickEmailEvent.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.array),
  className: PropTypes.string.isRequired
};

export default ClickEmailEvent;
